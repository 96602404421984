import * as React from "react";
import { graphql, PageProps } from "gatsby";
import {
  GatsbyImage,
  GatsbyImageProps,
  StaticImage,
} from "gatsby-plugin-image";
import styled from "styled-components";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import SEO from "../components/template/seo";
import PageHeader from "../components/template/page-header";
import ContentBlocks from "../components/content-blocks/content-blocks";
import { parseContent } from "../helpers/utilities";

const Posts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
`;
const PageTitle = styled.h1``;
const Post = styled.article`
  display: flex;
  flex-direction: column;
  flex-basis: 33.3333%;
  padding: 0 20px;
  margin: 0 0 3em;

  @media (max-width: 990px) {
    flex-basis: 50%;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;
const Meta = styled.div`
  margin: 0.5em 0 0;
  padding: 0 0 10px;
  display: flex;
  border-bottom: 1px solid #ccc;
`;
const ImageLink = styled(AniLink)`
  margin: 0 0 1.5em;
  display: block;
`;
const Image = styled(GatsbyImage)<GatsbyImageProps>``;
const Title = styled.h3`
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 0.3em;
`;
const Date = styled.div``;
const Excerpt = styled.div`
  margin: 1em 0;
  flex: 1;
`;
const Button = styled(AniLink)`
  margin-left: auto;
  font-weight: 400;
`;
const Pagination = styled.div`
  display: flex;
  flex-basis: 100%;
  padding: 20px;
`;
const Prev = styled(AniLink)`
  margin-right: auto;
`;
const Next = styled(AniLink)`
  margin-left: auto;
`;

const Page = (props: Props) => {
  const {
    data: {
      wpPage: page,
      allWpPost: { nodes: posts },
    },
    pageContext: { numPages, currentPage },
  } = props;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? page.uri : `${page.uri}${currentPage - 1}`;
  const nextPage = `${page.uri}${currentPage + 1}`;

  return (
    <>
      <SEO data={page.seo} />

      <div className="container">
        <PageTitle>Articles</PageTitle>
      </div>

      {posts.length > 0 ? (
        <div className="container">
          <Posts>
            {posts.map((post, index) => (
              <Post key={index}>
                <ImageLink fade to={post.uri}>
                  {post.featuredImage ? (
                    <Image
                      image={
                        post.featuredImage.node.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={post.featuredImage.node.altText}
                    />
                  ) : (
                    <img src="/placeholder.png" alt="" />
                  )}
                </ImageLink>

                <Title>
                  <AniLink fade to={post.uri}>
                    {post.title}
                  </AniLink>
                </Title>

                <Excerpt>{parseContent(post.excerpt)}</Excerpt>

                <Meta>
                  <Date>{post.date}</Date>

                  <Button to={post.uri}>Read more &raquo;</Button>
                </Meta>
              </Post>
            ))}
            {(!isFirst || !isLast) && (
              <Pagination>
                {!isFirst && <Prev to={prevPage}>← Previous Page</Prev>}
                {!isLast && <Next to={nextPage}>Next Page →</Next>}
              </Pagination>
            )}
          </Posts>
        </div>
      ) : null}
    </>
  );
};

export default Page;

export const pageQuery = graphql`
  query blog($id: String!, $skip: Int!, $limit: Int!) {
    wpPage(id: { eq: $id }) {
      content
      seo {
        ...SEO
      }
      title
      uri
    }

    allWpPost(
      limit: $limit
      skip: $skip
      sort: { fields: [isSticky, date], order: [DESC, DESC] }
    ) {
      nodes {
        date(formatString: "Do MMMM YYYY")
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 80)
              }
            }
          }
        }
        title
        uri
      }
    }
  }
`;
